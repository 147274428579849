export default class Height {

  constructor() {

    function setLoopDivsHeight() {
      var loopDivs = document.getElementsByClassName('loop');
      var maxHeight = 0;
      for (var i = 0; i < loopDivs.length; i++) {
        loopDivs[i].style.height = 'auto';
        var loopDivHeight = loopDivs[i].clientHeight;
        if (loopDivHeight > maxHeight) {
          maxHeight = loopDivHeight;
        }
      }
      for (var i = 0; i < loopDivs.length; i++) {
        loopDivs[i].style.height = maxHeight + 'px';
      }
    }
    
    window.addEventListener('load', function() {
      setLoopDivsHeight();
    });
    
    window.addEventListener('resize', function() {
      setLoopDivsHeight();
    });
  }
}