import AOS from 'aos';

export default class AOSAnimation {
  constructor() {
    this.initAOS();
  }

  initAOS() { 
    AOS.init({
      duration: 1200,
      once: true,
      disable: 'mobile'
    });
  }
}